.mat-mdc-form-field {
  @include mb (2);

  &.full-field {
    .mat-mdc-text-field-wrapper {
      width: 100%;
    }
  }

  .mat-mdc-text-field-wrapper {
    width:25rem;

    &.mdc-text-field--outlined {
      .mat-mdc-form-field-infix {
        padding-top: 10px;
        padding-bottom: 0;
        min-height: 40px;
      }

      .mdc-text-field__input {
        //caret-color: var(--mdc-theme-primary, $dark-grey);
      }

      .mdc-notched-outline {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          border-radius: 0;
          //border-color: $light-grey;
        }
      }
    }

    .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: 20px;
    }

    .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
      --mat-mdc-form-field-label-transform: translateY( -28.75px) scale(var(--mat-mdc-form-field-floating-label-scale, .75));
      transform: var(--mat-mdc-form-field-label-transform);
    }
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 40px;
    height: 40px;
    padding: 8px;
  }

  .mat-mdc-form-field-bottom-align::before {
    height:12px;
  }

  .mat-mdc-form-field-error {
    font-size: 0.75rem;
  }

  &.mat-mdc-paginator-page-size-select {
    .mat-mdc-text-field-wrapper {
      width: 100%;
    }
  }
}

//preorders
.preorders-input.mat-mdc-form-field .mat-mdc-text-field-wrapper {
  width: 5rem;
  //.mat-mdc-form-field-flex .mat-mdc-floating-label {
  //  top: 28px;
  //}
}




//Display


// we have to learn how to automate margins and paddings :)
//@use "./temp/spacing/margins";
//@use "./temp/spacing/paddings";

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.d-grid {
  display: grid;
}

//Flex helpers

.jcsb {
  justify-content: space-between;
}

.jcc {
  justify-content: center;
}

.jce {
  justify-content: end;
}

.jcs {
  justify-content: start;
}

.aic {
  align-items: center;
}

.aie {
  align-items: end;
}

.shadow {
  box-shadow: $box-shadow;
}

.red {
  //color: $red;
}

.cursor-pointer {
  cursor: pointer;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.bordered-text {
  border: 1px solid black;
  padding: 1rem 2rem;
}

.success {
  //border: 1px solid $dark-grey;
  //color: mat.get-theme-color($portal-theme, warn, '600-contrast');
}

.error {
  //border: 1px solid $dark-grey;
  //color: mat.get-theme-color($portal-theme, primary);
}

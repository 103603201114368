.pt-0 {
  @include pt (0)
}

.pt-xs {
  @include pt (1)
}

.pt-s {
  @include pt (2)
}

.pt-m {
  @include pt (3)
}

.pt-l {
  @include pt (4)
}

.pt-xl {
  @include pt (5)
}

.pt-xxl {
  @include pt (6)
}

.pb-0 {
  @include pb (0)
}

.pb-xs {
  @include pb (1)
}

.pb-s {
  @include pb (2)
}

.pb-m {
  @include pb (3)
}

.pb-l {
  @include pb (4)
}

.pb-xl {
  @include pb (5)
}

.pb-xxl {
  @include pb (6)
}


.pr-0 {
  @include pr (0)
}

.pr-xs {
  @include pr (1)
}

.pr-s {
  @include pr (2)
}

.pr-m {
  @include pr (3)
}

.pr-l {
  @include pr (4)
}

.pr-xl {
  @include pr (5)
}

.pr-xxl {
  @include pr (6)
}

.pl-0 {
  @include pl (0)
}

.pl-xs {
  @include pl (1)
}

.pl-s {
  @include pl (2)
}

.pl-m {
  @include pl (3)
}

.pl-l {
  @include pl (4)
}

.pl-xl {
  @include pl (5)
}

.pl-xxl {
  @include pl (6)
}

.px-0 {
  @include px (0)
}

.px-xs {
  @include px (1)
}

.px-s {
  @include px (2)
}

.px-m {
  @include px (3)
}

.px-l {
  @include px (4)
}

.px-xl {
  @include px (5)
}

.px-xxl {
  @include px (6)
}

.py-0 {
  @include py (0)
}

.py-xs {
  @include py (1)
}

.py-s {
  @include py (2)
}

.py-m {
  @include py (3)
}

.py-l {
  @include py (4)
}

.py-xl {
  @include py (5)
}

.py-xxl {
  @include py (6)
}

.p-0 {
  @include p (0)
}

.p-xs {
  @include p (1)
}

.p-s {
  @include p (2)
}

.p-m {
  @include p (3)
}

.p-l {
  @include p (4)
}

.p-xl {
  @include p (5)
}

.p-xxl {
  @include p (6)
}

//Fonts

$heading-font-family: 'Raleway SemiBold', Verdana, sans-serif;
$body-font-family: 'Lato Regular', Verdana, sans-serif;
$italic-font-family: 'Lato Italic', Verdana, sans-serif;
$bold-font-family: 'Lato Bold', Verdana, sans-serif;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default;
// scss-docs-end container-max-widths

@include _assert-ascending($container-max-widths, "$container-max-widths");

$box-shadow: rgba(84, 84, 84, 0.16) 0 0.25rem 0.5rem 0;



//Spacing

$spacing-sizes: (
  0: 0rem,
  1: 0.25rem,
  2: 0.5rem,
  3: 1rem,
  4: 2rem,
  5: 4rem,
  6: 8rem,
) !default;

$spacing-important: true !default;



.file-list {
  list-style: square;

  .material-icons {
    font-size: 2.5rem;
  }
}

.list-style-none {
  list-style: none;
}

.list-style-square {
  list-style: square;
}

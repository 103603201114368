.mt-0 {
  @include mt (0)
}

.mt-xs {
  @include mt (1)
}

.mt-s {
  @include mt (2)
}

.mt-m {
  @include mt (3)
}

.mt-l {
  @include mt (4)
}

.mt-xl {
  @include mt (5)
}

.mt-xxl {
  @include mt (6)
}

.mb-0 {
  @include mb (0)
}

.mb-xs {
  @include mb (1)
}

.mb-s {
  @include mb (2)
}

.mb-m {
  @include mb (3)
}

.mb-l {
  @include mb (4)
}

.mb-xl {
  @include mb (5)
}

.mb-xxl {
  @include mb (6)
}


.mr-0 {
  @include mr (0)
}

.mr-xs {
  @include mr (1)
}

.mr-s {
  @include mr (2)
}

.mr-m {
  @include mr (3)
}

.mr-l {
  @include mr (4)
}

.mr-xl {
  @include mr (5)
}

.mr-xxl {
  @include mr (6)
}

.ml-0 {
  @include ml (0)
}

.ml-xs {
  @include ml (1)
}

.ml-s {
  @include ml (2)
}

.ml-m {
  @include ml (3)
}

.ml-l {
  @include ml (4)
}

.ml-xl {
  @include ml (5)
}

.ml-xxl {
  @include ml (6)
}

.mx-0 {
  @include mx (0)
}

.mx-xs {
  @include mx (1)
}

.mx-s {
  @include mx (2)
}

.mx-m {
  @include mx (3)
}

.mx-l {
  @include mx (4)
}

.mx-xl {
  @include mx (5)
}

.mx-xxl {
  @include mx (6)
}

.my-0 {
  @include my (0)
}

.my-xs {
  @include my (1)
}

.my-s {
  @include my (2)
}

.my-m {
  @include my (3)
}

.my-l {
  @include my (4)
}

.my-xl {
  @include my (5)
}

.my-xxl {
  @include my (6)
}

.m-0 {
  @include m (0)
}

.m-xs {
  @include m (1)
}

.m-s {
  @include m (2)
}

.m-m {
  @include m (3)
}

.m-l {
  @include m (4)
}

.m-xl {
  @include m (5)
}

.m-xxl {
  @include m (6)
}

// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;
@use './main-palette' as mp;
@use './notifications-palette' as np;
@use './fonts' as f;
@use './tiles' as t;

@include mat.core();

$_rest: (
  secondary: map.get(mp.$palettes, secondary),
  neutral: map.get(mp.$palettes, neutral),
  neutral-variant: map.get(mp.$palettes, neutral-variant),
  error: map.get(mp.$palettes, error)
);
$_primary: map.merge(map.get(mp.$palettes, primary), $_rest);
$_tertiary: map.merge(map.get(mp.$palettes, tertiary), $_rest);

$light-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: $_primary,
    tertiary: $_tertiary
    //use-system-variables: true,
  ),
  typography: (
    plain-family: f.$body-font-family,
    brand-family: f.$heading-font-family,
    //use-system-variables: true
  ),
));

$dark-theme: mat.define-theme((
  color: (
    theme-type: dark,
    primary: $_primary,
    tertiary: $_tertiary,
  ),
  typography: (
    plain-family: f.$body-font-family,
    brand-family: f.$heading-font-family,
    //use-system-variables: true
  ),
));

$_primary_note: map.merge(map.get(np.$palettes, primary), $_rest);
$_tertiary_note: map.merge(map.get(np.$palettes, tertiary), $_rest);

$notify-theme: mat.define-theme((
  color: (
    theme-type: light,
    //use-system-variables: true,
    primary: $_primary_note,
    tertiary: $_tertiary_note
  ),
  typography: (
    plain-family: f.$body-font-family,
    brand-family: f.$heading-font-family,
    //use-system-variables: true
  ),
));


html {
  // Apply the light theme by default
  @include mat.all-component-themes($light-theme);
  //@include mat.strong-focus-indicators-theme($light-theme);

  // Apply the light theme only when the user prefers dark themes.
  //@media (prefers-color-scheme: dark) {
  //  // Use the `-color` mixins to only apply color styles without reapplying the same
  //  // typography and density styles.
  //  @include mat.all-component-themes($dark-theme);
  //  @include mat.strong-focus-indicators-theme($dark-theme);
  //}

  color: mat.get-theme-color($light_theme, neutral, 35);
  :root {
    --mat-table-background-color: mat.get-theme-color($light_theme, neutral, 100);
  }
  --mdc-elevated-card-container-color: mat.get-theme-color($light_theme, primary, 100);
  --mat-app-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-option-label-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-card-subtitle-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-filled-text-field-input-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-filled-text-field-hover-active-indicator-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-outlined-text-field-hover-label-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-outlined-text-field-input-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-outlined-text-field-hover-outline-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-form-field-state-layer-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-form-field-select-option-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-select-enabled-trigger-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-dialog-subhead-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-chip-with-icon-disabled-icon-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-chip-selected-disabled-trailing-icon-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-switch-disabled-selected-icon-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-switch-disabled-selected-track-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-switch-disabled-unselected-handle-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-switch-unselected-focus-state-layer-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-switch-unselected-hover-state-layer-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-switch-unselected-pressed-state-layer-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-switch-disabled-unselected-track-outline-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-switch-label-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-radio-disabled-selected-icon-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-radio-disabled-unselected-icon-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-radio-unselected-hover-icon-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-radio-unselected-focus-icon-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-radio-unselected-pressed-icon-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-radio-ripple-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-radio-label-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-slider-disabled-active-track-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-slider-disabled-handle-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-slider-disabled-inactive-track-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-slider-with-tick-marks-disabled-container-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-menu-item-label-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-list-list-item-disabled-state-layer-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-list-list-item-label-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-list-list-item-disabled-label-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-list-list-item-disabled-leading-icon-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-list-list-item-disabled-trailing-icon-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-list-list-item-hover-label-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-list-list-item-focus-label-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-list-list-item-hover-state-layer-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-list-list-item-focus-state-layer-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-paginator-container-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-tab-header-pagination-icon-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-tab-header-inactive-label-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-tab-header-active-label-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-tab-header-active-ripple-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-tab-header-inactive-ripple-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-tab-header-inactive-focus-label-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-tab-header-inactive-hover-label-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-tab-header-active-focus-label-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-tab-header-active-hover-label-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-checkbox-unselected-focus-icon-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-checkbox-unselected-hover-icon-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-checkbox-selected-pressed-state-layer-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-checkbox-unselected-focus-state-layer-color: mat.get-theme-color($light_theme, neutral, 35);
  --mdc-checkbox-unselected-hover-state-layer-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-checkbox-label-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-table-header-headline-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-table-row-item-label-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-bottom-sheet-container-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-standard-button-toggle-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-standard-button-toggle-state-layer-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-datepicker-calendar-body-label-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-datepicker-calendar-date-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-datepicker-range-input-separator-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-datepicker-calendar-container-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-expansion-container-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-expansion-header-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-sidenav-content-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-sort-arrow-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-toolbar-container-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-tree-node-text-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-stepper-container-color: mat.get-theme-color($light_theme, neutral, 100);
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-button-container-shape: 4px;
  --mdc-text-button-container-shape: 0px;
  --mat-stepper-header-hover-state-layer-shape: 4px;
  --mat-stepper-header-focus-state-layer-shape: 4px;
  --mdc-outlined-button-label-text-color: mat.get-theme-color($light_theme, neutral, 22);
  --mdc-outlined-button-outline-color: mat.get-theme-color($light_theme, neutral, 35);
  --mat-text-button-ripple-color: mat.get-theme-color($light_theme, neutral, 95);
  --mat-outlined-button-ripple-color: mat.get-theme-color($light_theme, neutral, 95);
  --mat-table-background-color: #ffffff;
  --mdc-filled-text-field-container-color: mat.get-theme-color($light_theme, neutral, 95);
  --mdc-filled-button-container-shape: 0px;
  --mdc-fab-small-container-color:  #ed1c24;
  --mdc-fab-small-container-shape: 20px;
  --mat-fab-small-foreground-color: #ffffff;
  --mat-expansion-container-shape: 4px;
  --mat-expansion-container-background-color: #ffffff;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 1000px;
  --mat-legacy-button-toggle-height: 3;


  font-family: f.$body-font-family;

  .red {
    color: #ed1c24;
  }

  .mdc-button.mat-neutral,
  .mat-mdc-flat-button.mat-neutral,
  .mat-mdc-stroked-button.mat-neutral,
  .mat-mdc-icon-button.mat-neutral{
    color: mat.get-theme-color($light_theme, neutral, 35);
  }

  .mat-mdc-button.mat-neutral:hover,
  .mat-mdc-raised-button.mat-neutral:hover,
  .mat-mdc-flat-button.mat-neutral:hover,
  {
    color: mat.get-theme-color($light_theme, neutral, 35);
    background-color: mat.get-theme-color($light_theme, neutral, 95);
  }

  .mat-mdc-button:not(:disabled, :hover).mat-neutral,
  .mat-mdc-raised-button.mat-neutral,
  .mat-mdc-fab.mat-neutral,
  .mat-mdc-flat-button.mat-neutral,
  .mat-mdc-mini-fab.mat-neutral {
    color: mat.get-theme-color($light_theme, neutral, 22);
    background-color: mat.get-theme-color($light_theme, neutral, 100);
  }

  .mat-mdc-icon-button.mat-neutral .mat-mdc-button-persistent-ripple::before,
  .mat-mdc-button.mat-neutral .mat-mdc-button-persistent-ripple::before {
    background-color: transparent;
  }


  .mat-mdc-unelevated-button.mat-mdc-button-base {
    height: 40px;

    .mdc-button__label {
      font-family: f.$bold-font-family;
      font-size: 0.875rem;
    }
  }

  .mat-mdc-flat-button.mat-neutral {
    font-family: f.$bold-font-family;
    font-size: 0.875rem;
  }

  .mat-mdc-button.mat-neutral {
    font-family: f.$bold-font-family;
    font-size: 0.875rem;
  }

  .mat-mdc-outlined-button.mat-mdc-button-base {
    &.days {
      padding: 1rem 2rem;
      font-size: large;
    }
  }

  .available {
    padding: 10px 20px;
    background: mat.get-theme-color($notify-theme, primary, 95);
  }

  .subsequent {
    padding: 10px 20px;
    background: mat.get-theme-color($light-theme, secondary, 80);
  }

  .not-available {
    padding: 10px 20px;
    background: mat.get-theme-color($light-theme, neutral, 80);
  }


  //Cards
  .mat-mdc-card {
    &.mat-badge {
      .mat-badge-content {
        left: 90%;
        bottom: 95%;
        transform: rotate(45deg);
        line-height: 2.5;
        font-size: 1rem;
      }
    }
  }
}

//Spacing
$spacing-sizes: (
  0: 0rem,
  1: 0.25rem,
  2: 0.5rem,
  3: 1rem,
  4: 2rem,
  5: 4rem,
  6: 8rem,
) !default;

$spacing-important: true !default;

@import 'material-symbols';
@import './mixins';
@import './variables';
@import './mixins/spacing';
@import './temp/spacing/margins';
@import './temp/spacing/paddings';
@import './utilities';
@import './material/forms';
@import "./responsive";
@import "./lists";
@import "./editor";
@import './typography';
//@import './overwrite';


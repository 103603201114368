.tile-grid {
  display: grid;
  gap: 2rem;
  grid-template-areas:
    "quick . track-trace ."
    "belege umsatz nachlieferung shop"
    "aktuelle covid-19 grippe-2025 daten"
    "hgf kontakt grippe-2024 mitbenutzer";
  grid-template-columns: repeat(4, 1fr);
}

.icon {
  height: 6rem;

  &.icon-belege {
    background: url("/assets/tiles/belege.svg") no-repeat center;

    &:hover {
      background: url("/assets/tiles/belege-hover.svg") no-repeat center;
    }
  }

  &.icon-umsatz {
    background: url("/assets/tiles/umsatz.svg") no-repeat center;

    &:hover {
      background: url("/assets/tiles/umsatz-hover.svg") no-repeat center;
    }
  }

  &.icon-nachlieferung {
    background: url("/assets/tiles/nachlieferung.svg") no-repeat center;

    &:hover {
      background: url("/assets/tiles/nachlieferung-hover.svg") no-repeat center;
    }
  }

  &.icon-kontakt {
    background: url("/assets/tiles/kontakt.svg") no-repeat center;
    &:hover {
      background: url("/assets/tiles/kontakt-hover.svg") no-repeat center;
    }
  }

  &.icon-meine-daten {
    background: url("/assets/tiles/meine-daten.svg") no-repeat center;
    &:hover {
      background: url("/assets/tiles/meine-daten-hover.svg") no-repeat center;
    }
  }

  &.icon-mitbenutzer {
    background: url("/assets/tiles/mitbenutzer.svg") no-repeat center;
    &:hover {
      background: url("/assets/tiles/mitbenutzer-hover.svg") no-repeat center;
    }
  }

  &.icon-aktuelle-informationen {
    background: url("/assets/tiles/aktuelle-informationen.svg") no-repeat center;
    &:hover {
      background: url("/assets/tiles/aktuelle-informationen-hover.svg") no-repeat center;
    }
  }

  &.icon-covid-19-informationen {
    background: url("/assets/tiles/covid-19-informationen.svg") no-repeat center;
    &:hover {
      background: url("/assets/tiles/covid-19-informationen-hover.svg") no-repeat center;
    }
  }

  &.icon-chargen {
    background: url("/assets/tiles/chargen.svg") no-repeat center;
    &:hover {
      background: url("/assets/tiles/chargen-hover.svg") no-repeat center;
    }
  }

  &.icon-hgf {
    background: url("/assets/tiles/hgf.svg") no-repeat center;
    &:hover {
      background: url("/assets/tiles/hgf-hover.svg") no-repeat center;
    }
  }

  &.icon-grippe {
    background: url("/assets/tiles/grippe.svg") no-repeat center;
    &:hover {
      background: url("/assets/tiles/grippe-hover.svg") no-repeat center;
    }
  }

  &.icon-shop {
    background: url("/assets/tiles/search.svg") no-repeat center;
    &:hover {
      background: url("/assets/tiles/search-hover.svg") no-repeat center;
    }
  }
}

$huge: 5rem;
$xxxlarge: 4rem;
$xxlarge: 3.5rem;
$xlarge: 2.5rem;
$large: 2rem;
$medium: 1.5rem;
$small: 1rem;
$xsmall: 0.5rem;

.text-large {
  font-size: $large;
}

.text-xlarge {
  font-size: $xlarge;
}

.text-xxlarge {
  font-size: $xxlarge;
}

.text-xxxlarge {
  font-size: $xxxlarge;
}

.text-huge {
  font-size: $huge;
}

.text-small {
  font-size: 0.75rem;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-line-through{
  text-decoration: line-through;
}

.text-underline{
  text-decoration: underline;
}

.text-overline{
  text-decoration: overline;
}

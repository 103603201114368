@font-face {
  font-family: 'Lato Regular';
  font-style: normal;
  src: local('Lato Regular'), local('Lato-Regular'),
  url(/assets/fonts/app/Lato-Regular.ttf);
}
@font-face {
  font-family: 'Lato Italic';
  font-style: normal;
  src: local('Lato Italic'), local('Lato-Italic'),
  url(/assets/fonts/app/Lato-LightItalic.ttf);
}
@font-face {
  font-family: 'Lato Bold';
  font-style: normal;
  src: local('Lato Bold'), local('Lato-Bold'),
  url(/assets/fonts/app/Lato-Bold.ttf);
}
@font-face {
  font-family: 'Raleway SemiBold';
  font-weight: 600;
  src: local('Raleway SemiBold'), local('Raleway-SemiBold'),
  url(/assets/fonts/app/Raleway-SemiBold.ttf);
}

$heading-font-family: 'Raleway SemiBold', Verdana, sans-serif;
$body-font-family: 'Lato Regular', Verdana, sans-serif;
$italic-font-family: 'Lato Italic', Verdana, sans-serif;
$bold-font-family: 'Lato Bold', Verdana, sans-serif;
